import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import Img from '../styles/Img';
import Box from '../styles/Box';
import Container from '../styles/Container';

import { Link, navigate } from 'gatsby';
// import { colors, shadows } from '../../theme';

import { tagColor } from '../../utils/helpers';

const IconButton = styled.div`
  /* cursor: pointer; */
  text-align: left;
  line-height: 1.5;
  text-align: center;
  height: 275px;
  /* box-shadow: ${shadows.small}; */
  padding: 0px;

  &:hover {
    background-color: #fff;
    /* box-shadow: ${shadows.medium}; */
  }

  &:focus {
    /* box-shadow: ${shadows.outline}; */
  }
`;

const MainImage = styled(Img)`
  width: 100%;
  height: 200px;
`;


const RatingDiv = styled.div`
  position: relative;
  float: right;
  margin-top: -35px;
  margin-right: 5px;
  z-index: 1;
  top: 40px;
  border-radius: 5px;
  background-color: #f9f9fa;
`;

const Tag = styled.div`
  position: relative;
  float: right;
  margin-top: -35px;
  margin-right: 5px;
  z-index: 1;
  top: 70px;
  border-radius: 5px;
  background-color: #f9f9fa;
`;

const CardText = styled(Text)`
  padding-bottom: 10px;
  padding-top: 3px;
  text-align: left;
  font-family: system-ui, sans-serif;
  font-weight: 500;
`;

const PreviewText = styled(Text)`
  font-family: Georgia, 'Times New Roman', Times, serif;
  letter-spacing: -0.003em;
  line-height: 1.3em;
  -webkit-font-smoothing: antialiased;
`;

class MainPost extends React.Component {
  render() {
    const trimmedPreview = this.props.data.bodyPreview.substr(0, 160);

    return (
      <div>
        <Container my={3}>
          <Link to={this.props.slug} state={{ fromFeed: true }} />
          <Link to={this.props.slug} state={{ fromFeed: true }}>
            <MainImage fluid={this.props.data.featuredImage.fluid} />
          </Link>
          <Flex alignItems="center" flexWrap="wrap" py={2}>
            <Link to={this.props.slug} state={{ fromFeed: true }}>
              <CardText
                color="black"
                fontSize={['24px', '28px', '30px']}
                mb={2}
              >
                {this.props.name}
              </CardText>
              <PreviewText color="rgb(95, 111, 118)" fontSize="13px">
                {trimmedPreview}..
              </PreviewText>
            </Link>
          </Flex>
        </Container>
      </div>
    );
  }
}

export default MainPost;
