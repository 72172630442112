import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import Container from '../components/styles/Container';
import Page from '../components/Page';
import { graphql } from 'gatsby';
import Mission from '../components/about/Mission';
import ThreeDads from '../components/about/ThreeDads';
import HeroPost from '../components/blog/HeroPost';
import MainGrid from '../components/blog/MainGrid';

const Banner = styled.div`
  background #000;
  padding: 0 16px;
`;

class BlogPage extends React.Component {
  render() {
    const { data } = this.props;

    // save the first post props to feed to HeroPost component
    const firstPost = this.props.data.allContentfulBlogPost.edges[0].node;

    // remove first item from array and send to MainGrid components
    const secondPosts = this.props.data.allContentfulBlogPost.edges.slice(1);

    return (
      <div>
        <Page>
          <Banner>
            <HeroPost
              title={firstPost.title}
              slug={firstPost.slug}
              featuredImage={firstPost.featuredImage.fluid}
              preview={firstPost.bodyPreview}
            />
          </Banner>
          <Container>
            <MainGrid posts={secondPosts} />
          </Container>
        </Page>
      </div>
    );
  }
}

export default BlogPage;

export const query = graphql`
  query BlogPosts {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          id
          publishDate
          title
          bodyPreview
          slug
          featuredImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
