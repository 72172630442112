import React, { Component } from 'react';
import { Flex, Heading, Text } from 'rebass';
import Img from '../styles/Img';
import Box from '../styles/Box';
import styled from 'styled-components';
import Container from '../styles/Container';
import { Link } from 'gatsby';

import { colors, shadows } from '../../theme';

const PreviewText = styled(Text)`
  font-family: Georgia, 'Times New Roman', Times, serif;
  letter-spacing: -0.003em;
  font-size: 20px;
  line-height: 1.6em;
  -webkit-font-smoothing: antialiased;
`;

class HeroPost extends Component {
  render() {
    return (
      <div>
        <Link to={`/${this.props.slug}`}>
          <Container py="10vh">
            <Flex flexWrap="wrap" mx="auto" pt="20px">
              <Box width={[1]} pt="40px">
                <Img fluid={this.props.featuredImage} />
              </Box>
              <Box width={1}>
                <Heading
                  as="h6"
                  fontSize={[4, 5, 6]}
                  fontFamily="system-ui, sans-serif"
                  fontWeight="700"
                  my="10px"
                  color="white"
                >
                  {this.props.title}
                </Heading>
                <PreviewText my="10px" color="rgb(165, 183, 180)">
                  {this.props.preview}
                </PreviewText>
              </Box>
            </Flex>
          </Container>
        </Link>
      </div>
    );
  }
}

export default HeroPost;
