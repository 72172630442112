import React, { Component } from 'react';
import { Flex } from 'rebass';
import Text from '../styles/Text';
import Box from '../styles/Box';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { padding } from '../../theme';
import Heading from '../styles/Heading';
import Subhead from '../styles/Subhead';

const BlockImage = styled.img`
  width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

export default () => (
  <StaticQuery
    query={graphql`
      query Mission {
        contentfulAboutPage(live: { eq: true }) {
          id
          element2Block1Title
          element2Block1Subhead
          element2Block2Title
          element2Block2Subhead
        }
      }
    `}
    render={data => (
      <div>
        <Flex py={padding} flexWrap="wrap">
          <Box width={1} px={3} color="black">
            <Subhead px={2} pt={5} fontSize="40px">
              {data.contentfulAboutPage.element2Block1Title.toUpperCase()}
            </Subhead>
            <Text px={2} py={5} fontSize="20px">
              {data.contentfulAboutPage.element2Block1Subhead}
            </Text>
          </Box>
          {/* Box 2 */}
          <Box width={1} px={3} color="black">
            <Subhead px={2} pt={5} fontSize="40px">
              {data.contentfulAboutPage.element2Block2Title.toUpperCase()}
            </Subhead>
            <Text px={2} py={5} fontSize="20px">
              {data.contentfulAboutPage.element2Block2Subhead}
            </Text>
          </Box>
        </Flex>
      </div>
    )}
  />
);
