import React from 'react';
import { Flex, Text, Heading } from 'rebass';
import MainPost from './MainPost';
import Box from '../styles/Box';

class MainGrid extends React.Component {
  render() {
    return (
      <Flex flexWrap="wrap" my={20}>
        {this.props.posts.map(post => (
          <Box key={post.node.title} width={[1, 1 / 2]} px="15px" py="20px">
            <MainPost
              // Clean this up to only send data
              name={post.node.title}
              slug={post.node.slug}
              data={post.node}
              w={1}
            />
          </Box>
        ))}
      </Flex>
    );
  }
}

export default MainGrid;
